import "./Cookies.css";
import React from "react";

const RefundPolicy = () => {
  return (
    <div
      className="container-fluid privacyPolicyImgbox"
      style={{
        width: "100%",
        minHeight: "51rem",
        position: "relative",
        backgroundImage: `url(./main-bg-landscap.jpg)`,
        backgroundSize: "cover",
        backgroundRepeat: "round",
        backgroundBlendMode: "overlay",
      }}
    >
      <div className="container privacyPolicyBellowTextBox">
        <div id="scroll">
          <div className="row">
            <div className="col-md-12 privacyContentBox">
              <h3
                style={{
                  color: "#008dc4",
                  paddingBottom: "10px",
                  paddingTop: "50px",
                }}
              >
                REFUND POLICY
              </h3>

              <h6 style={{ color: "#007cc2" }}>
                PLEASE READ AND MAKE SURE YOU FULLY UNDERSTAND OUR REFUND POLICY
                PRIOR TO MAKING A PAYMENT
              </h6>
              <p style={{ color: "black" }}>
                This Policy applies to ubeetu Site/ App platforms including
                without limitation www.ubeetu.com (https://www.ubeetu.com) and
                other related Site/s or App/s, mobile applications and other
                online features each a “Site/s or App/s”. We have provided
                extensive information for you to view the packages before
                choosing to subscribe to us. If you have any questions or
                reservations, please contact us at info@ubeetu.com prior to
                subscribing to our services. We, being the service providers for
                contents available through SITE or APP, where you are expected
                to view packages of your choice after being paid for
                subscription; unfortunately, all fees to ubeetu for such
                services are non-refundable. In case, because of any technical
                glitch at the time of online transaction, the transaction does
                not occur, the amount in process of transfer by default goes
                back into your bank account, automatically through Payment
                Gateway.
              </p>

              <h3 style={{ color: "#007cc2", paddingBottom: "10px" }}>
                CHARGEBACKS
              </h3>
              <p style={{ color: "black" }}>
                If we receive a chargeback or payment dispute from a credit card
                company or bank, your service and/or subscription will be
                suspended without notice.
                <br />
                Applicable chargeback fee will be issued to recover fees passed
                on to us by the credit company, plus any outstanding balances
                accrued as a result of the chargeback(s) must be paid in full
                before service is restored.
                <br />
                Instead of issuing a chargeback, contact us to address any
                billing issues. Requesting a chargeback or opening any sort of
                dispute for a valid charge from us is fraud, and is never an
                appropriate or legal means of obtaining a refund.
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
