import React, { useEffect, useState } from "react";

import "./Login.css"; // You can define your custom styles for the Login component
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import CustomInput from "../FooterPages/CustomInput";
import { useUser } from "../../UserContext";
import instance from "../common/Api";

const ForgotPassword = () => {
  // const history = useHistory();
  const [showOTPView, setShowOTPView] = useState(false);
  const [mobile, setMobile] = useState("");

  const [newPass, setNewPass] = useState("");
  const [confPass, setConfPass] = useState("");

  const [otp, setOtp] = useState("");
  const [showOtpField, setShowOtpField] = useState(false);

  const [showResetPass, setShowResetPass] = useState(false);

  //https://adminpanelconnect.ubeetu.com/userApi/otp/validate?mobileNumber=6543216543&otpText=123456

  //   https://adminpanelconnect.ubeetu.com/userApi/reset_password_web?mobileNumber=6543216543&password=123456&password_confirmation=123456

  const HandleSendOTP = async () => {
    if (mobile.length === 10) {
      //console.log("LoginHandle:", mobile); //6543216543
      instance
        .post(`/forgot_password_web?mobile=${mobile}`)
        .then((response) => {
          const data = response.data;
          if (data.success) {
            setShowOtpField(true);
            alert("OTP Successfully sent to your registered number");
          } else {
            alert(response.data.msg);
          }
          /*
{
    "success": true,
    "id": 5806,
    "mobile": "6543216543",
    "otptext": 454003
}
*/
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {});
    } else {
      alert("Please enter a valid mobile number and password.");
    }
  };

  const HandleVerifyOTP = async () => {
    if (otp.length === 6) {
      //console.log("LoginHandle:", mobile); //6543216543
      instance
        .post(`/otp/validate?mobileNumber=${mobile}&otpText=${otp}`)
        .then((response) => {
          const data = response.data;
          if (data.success) {
            alert("OTP Successfully sent to your registered number");
            setShowResetPass(true);
          } else {
            alert(data.message);
          }
          /*
{
    "success": true,
    "id": 5806,
    "mobile": "6543216543",
    "otptext": 454003
}
*/
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {});
    } else {
      alert("Please enter a valid mobile number and password.");
    }
  };

  const HandleResetPassword = async () => {
    if (newPass === confPass && newPass !== "" && confPass !== "") {
      instance
        .post(
          `/reset_password_web?mobileNumber=${mobile}&password=${newPass}&password_confirmation=${newPass}`
        )
        .then((response) => {
          const data = response.data;
          if (data.success) {
            window.location.href = "/login";
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {});
    } else {
      alert("Please enter a valid mobile number and password.");
    }
  };
  return (
    <div
      style={{
        paddingTop: "5rem",
        paddingTop: "3rem",
        width: "100%",
        minHeight: "51rem",
        position: "relative",
        backgroundImage: `url(./main-bg-landscap.jpg)`,
        backgroundSize: "cover",
        backgroundRepeat: "round",
        backgroundBlendMode: "overlay",
      }}
    >
      {showResetPass ? (
        <Container className="custom-container-forgot">
          <Row>
            <h3 style={{ color: "#008dc4" }}> Reset Password</h3>
          </Row>
          <Col>
            <Form>
              <CustomInput
                label="Password"
                //   placeholder="Enter your mobile number"
                error="Password field cannot be empty"
                type="password"
                onValueChange={(value) => setNewPass(value)}
              />
              <CustomInput
                label="Confirm Password"
                // placeholder="Enter your OTP"
                error={newPass === confPass ? "" : "Password not macthed"}
                type="password"
                onValueChange={(value) => setConfPass(value)}
              />
            </Form>
            <Row className="mt-3 mt-md-0">
              <Col xs={12} md={4} className="mb-3 mb-md-0 pr-md-1"></Col>
              <Col xs={12} md={4} className="mb-3 mb-md-0 pr-md-1">
                <Button
                  style={{ backgroundColor: "#008dc4", borderWidth: "0" }}
                  className="w-100 text-white"
                  // disabled={}
                  onClick={() => HandleResetPassword()}
                >
                  RESET
                </Button>
              </Col>
              <Col xs={12} md={4} className="pl-md-1"></Col>
            </Row>
          </Col>
        </Container>
      ) : (
        <Container className="custom-container-forgot">
          <Row>
            <h3 style={{ color: "#008dc4" }}> Forgot Password</h3>
          </Row>
          <Col>
            <Form>
              <CustomInput
                maxLength={10}
                label="Mobile Number"
                //   placeholder="Enter your mobile number"
                error="Please enter a 10-digit mobile number"
                type="number"
                onValueChange={(value) => setMobile(value)}
                disable={showOtpField}
              />
              <p className="custom-muted" style={{ color: "#008dc4" }}>
                Only Indian Mobile Numbers are allowed.
              </p>
              {showOtpField && (
                <CustomInput
                  maxLength={6}
                  label="OTP"
                  // placeholder="Enter your OTP"
                  error="OTP field cannot be empty"
                  type="text"
                  onValueChange={(value) => setOtp(value)}
                />
              )}
            </Form>
            <Row className="mt-3 mt-md-0">
              <Col xs={12} md={4} className="mb-3 mb-md-0 pr-md-1"></Col>
              <Col xs={12} md={4} className="mb-3 mb-md-0 pr-md-1">
                <Button
                  style={{ backgroundColor: "#008dc4", borderWidth: "0" }}
                  className="w-100 text-white"
                  // disabled={}
                  onClick={() =>
                    showOtpField ? HandleVerifyOTP() : HandleSendOTP()
                  }
                >
                  {showOtpField ? "VERIFY" : "REQUEST"}
                </Button>
              </Col>
              <Col xs={12} md={4} className="pl-md-1"></Col>
            </Row>
            {showOtpField && (
              <Row className="mt-3 mt-md-0">
                <Col xs={12} md={4} className="mb-3 mb-md-0 pr-md-1"></Col>

                <Col xs={12} md={4} className="mb-3 mb-md-0 pr-md-1">
                  {/* <p>resend otp in</p> */}
                  <Button
                    style={{ backgroundColor: "#008dc4", borderWidth: "0" }}
                    className="w-100 text-white"
                    // disabled={}
                    onClick={() => HandleSendOTP()}
                  >
                    RESEND
                  </Button>
                </Col>
                <Col xs={12} md={4} className="pl-md-1"></Col>
              </Row>
            )}
          </Col>
        </Container>
      )}
    </div>
  );
};

export default ForgotPassword;
{
  /* <Container className="custom-container-forgot">
        <Row>
          <h3 style={{ color: "white" }}> Forgot Password</h3>
        </Row>
        <Col>
          <Form>
            <CustomInput
              maxLength={10}
              label="Mobile Number"
              //   placeholder="Enter your mobile number"
              error="Please enter a 10-digit mobile number"
              type="number"
              onValueChange={(value) => setMobile(value)}
              disable={showOtpField}
            />
            <p className="custom-muted">
              Only Indian Mobile Numbers are allowed, others may use Social
              Login
            </p>
            {showOtpField && (
              <CustomInput
                maxLength={6}
                label="OTP"
                // placeholder="Enter your OTP"
                error="OTP field cannot be empty"
                type="text"
                onValueChange={(value) => setOtp(value)}
              />
            )}
          </Form>
          <Row className="mt-3 mt-md-0">
            <Col xs={12} md={4} className="mb-3 mb-md-0 pr-md-1"></Col>
            <Col xs={12} md={4} className="mb-3 mb-md-0 pr-md-1">
              <Button
                style={{ backgroundColor: "#008dc4", borderWidth: "0" }}
                className="w-100 text-white"
                // disabled={}
                onClick={() =>
                  showOtpField ? HandleVerifyOTP() : HandleSendOTP()
                }
              >
                {showOtpField ? "VERIFY" : "REQUEST"}
              </Button>
            </Col>
            <Col xs={12} md={4} className="pl-md-1"></Col>
          </Row>
          {showOtpField && (
            <Row className="mt-3 mt-md-0">
              <Col xs={12} md={4} className="mb-3 mb-md-0 pr-md-1"></Col>

              <Col xs={12} md={4} className="mb-3 mb-md-0 pr-md-1">
                
                <Button
                  style={{ backgroundColor: "#008dc4", borderWidth: "0" }}
                  className="w-100 text-white"
                  // disabled={}
                  onClick={() => HandleSendOTP()}
                >
                  RESEND
                </Button>
              </Col>
              <Col xs={12} md={4} className="pl-md-1"></Col>
            </Row>
          )}
        </Col>
      </Container> */
}
